@import url("https://fonts.cdnfonts.com/css/campton");

/* CSS Variables */
:root {
  --bg-color: #ecf0f3;
  --sec-color: #a3320b;
  --grey-color: #2f2e2e;
  --container-bg: #e1dfdf89;

  --dark-color: #000;
  --dark-bold-color: #1c1c1c;
  --light-color: #fff;
  --light-bold-color: #0000000d;
  --grey-bold-color: #7f7e7f;


}
/* CSS Variables */

/* Dark Mode Variables */
[data-theme="dark"] {
   --bg-color: #0c0c0e;
  --sec-color: #ff4102;
  --grey-color: #ffffff;
  --container-bg: #161618;

  --dark-color: #fff;
  --dark-bold-color: #ededed;
  --light-color: #000;
  --light-bold-color: #1c1c1c;
  --grey-bold-color: #7f7e7f;
}
/* Dark Mode Variables */
