/* Global */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  /* background-color: var(--bg-color); */
  margin: 0 auto;
  color: var(--grey-color) ;
  line-height: 32px !important;
  font-family: "Campton", sans-serif;
  overflow-x: hidden !important;
}

/* Global */

/* Scrollbar  */

::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}
::-webkit-scrollbar-track {
  border: none;
  background-color: var(--grey-color);
}
::-webkit-scrollbar-thumb {
  background: var(--light-color);
  height: 10px;
}
/* Scrollbar  */

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700 !important;
}

h1,
h2,
h3,
h4,
h5,
h6, p, small {
word-wrap: break-word;
}

/* Loader */
.app {
  width: 100%;
  height: 100vh;
  background: transparent;
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1003 !important;
}
/* Loader */

/* Colors */
.transparent {
  background: transparent !important;
}

.sec-text {
  color: var(--sec-color) !important;
}
.sec {
  background-color: var(--sec-color) !important;
}

.light-bold-text {
  color: var(--light-bold-color) !important;
}
.light-text {
  color: var(--light-color) !important;
}
.dark-text {
  color: var(--dark-color) !important;
}
.grey-text {
  color: var(--grey-color) !important;
}
.grey-bold-text {
  color: var(--grey-bold-color) !important;
}
.grey-bg {
  background-color: var(--grey-color) !important;
}
.grey-bold-bg {
  background-color: var(--grey-bold-color) !important;
}
.light-bg {
  background-color: var(--light-color) !important;
}
.light-bold-bg {
  background-color: var(--light-bold-color) !important;
}
.dark-bg {
  background-color: var(--dark-color) !important;
}
.dark-bold-bg {
  background-color: var(--dark-bold-color) !important;
}

/* Colors */

/* Button */
.btn {
  text-transform: capitalize !important;
  border-radius: 3px !important;
  padding: 10px 30px !important;
  align-items: center !important;
}

.w-lg-75 {
  width: 75% !important;
}
.w-lg-50 {
  width: 50% !important;
}

/* Button */

/* Hover and Shadow */
.hover:hover {
  transform: scale(1.1) !important;
  transition: ease 0.7s !important;
}

.shadow {
  /* box-shadow: 0px 25px 21px -20px #6262624d !important; */
  box-shadow: 0px 59px 31px -44px #626262c2 !important;
}

.shadow-sm {
  box-shadow: 0 0.5rem 1rem -3px #00000026 !important;
}

/* Hover and Shadow */

/* border radius */
.br-sm {
  border-radius: 5px !important;
}
.br-md {
  border-radius: 12px !important;
}
.br-lg {
  border-radius: 3rem !important;
}
.br-xlg {
  border-radius: 5rem !important;
}
/* border radius */

/* Pointer */
.pointer {
  cursor: pointer !important;
}
/* Pointer */

.fit-content {
  width: fit-content !important;
}

.min-height-lg {
  min-height: 70vh !important;
}

/* Modal */
.modal-header {
  border-bottom: none !important;
}
/* Modal */

/* Global */
/* Global */

/* Media Query for Mobile device */
/* Media Query for Mobile device */

@media screen and (max-width: 780px) {
}
@media screen and (max-width: 580px) {
  /* Button */
  .w-md-100 {
    width: 100% !important;
  }
  /* Button */
}
